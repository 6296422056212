<template>
<b-container fluid>
  <bo-page-title />


  <b-card v-if="isList" no-body>
    <b-card-header>
      <b-row>
        <b-col md="6"></b-col>
        <b-col md="3">
          <date-picker placeholder="Filter Date" id="dateGuarantee" value-type="format" format="YYYY-MM-DD" range prefix-class="crm" v-model="dateFilter" @input="doFilterDate" v-b-tooltip.hover="'Filter Guarantee Date'" :disabled-date="notAfterToday" />
        </b-col>
        <b-col cols="auto">
          <SearchInput :value.sync="filter.search" @search="doFilter" />
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body class="p-0">
      <b-table
        :fields="fields"
        :items="dataList||[]"
        :primary-key="idKey"
        :busy="!dataList"
        responsive
        show-empty
        bordered
        striped
      >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-3 text-info"></b-spinner>
            <strong class="text-info">Loading...</strong>
          </div>
        </template>
        <template #cell(number)="v">
          {{(data.per_page*(data.current_page-1))+v.index+1}}
        </template>
        <template #cell(aioc_date)="data">
          {{ data.value | moment('LL') }}
        </template>

        <template #cell(aioc_debit)="data">
          {{ data.value == 0 ? '-' : formatRupiah(data.value) }}
        </template>
        <template #cell(aioc_credit)="data">
          {{ data.value == 0 ? '-' : formatRupiah(data.value) }}
        </template>
        <template #cell(aioc_balance)="value">
          <span v-if="(data.per_page*(data.current_page-1))+value.index+1 == data.total">
            <strong>
              {{ value.value == 0 ? '-' : formatRupiah(value.value) }}
            </strong>
          </span>
          <span v-else>
            {{ value.value == 0 ? '-' : formatRupiah(value.value) }}
          </span>
        </template>
      </b-table>
    </b-card-body>
    <b-card-footer>
      <template v-if="(dataList||[]).length&&data.total>10">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <label for="perPage" class="mb-0 mr-2">Show</label>
              <b-form-select
                size="sm"
                id="perPage"
                class="w-auto mx-50"
                v-model="perPage"
                :options="Config.dataShownOptions"
              />
            <label for="perPage" class="mb-0 ml-2">items per page</label>
          </div>
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </div>
      </template>
    </b-card-footer>


    <b-modal
      id="addGuarantee"
      title="Add Guarantee"
      centered
      no-close-on-backdrop
    >
      <validation-observer v-slot="{ handleSubmit }" ref="VFormGuarantee">
        <b-form @submit.prevent="handleSubmit(addGuarantee())">
          <b-form-group label="Guarantee Until" label-for="guaranteedate">
            <date-picker type="date" value-type="format" format="YYYY-MM-DD" id="guaranteedate" prefix-class="crm" v-model="dataModal.tp_guarantee" :disabled-date="notBeforeToday" />
            <VValidate name="Guarantee Until" v-model="dataModal.tp_guarantee" rules="required" />
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button size="md" variant="transparent" @click="closeModal('addGuarantee')">Cancel</b-button>
        <b-button size="md" variant="info" @click="addGuarantee()">Add Guarantee</b-button>
      </template>
    </b-modal>

    <b-modal
      id="addTestimoni"
      title="Add Testimonial"
      centered
      no-close-on-backdrop
    >
      <validation-observer v-slot="{ handleSubmit }" ref="VFormTestimoni">
        <b-form @submit.prevent="handleSubmit(addTestimoni())">
          <b-form-group label="Testimonial" label-for="testimoni">
            <b-form-textarea id="testimoni" rows="3" placeholder="e.g. pemasangan sudah berjalan 50%" v-model="dataModal.tp_testimoni" />
            <VValidate name="Testimonial" v-model="dataModal.tp_testimoni" rules="required|min:5" />
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button size="md" variant="transparent" @click="closeModal('addTestimoni')">Cancel</b-button>
        <b-button size="md" variant="info" @click="addTestimoni()">Add Testimonial</b-button>
      </template>
    </b-modal>

    <b-modal
      id="editCustomer"
      title="Edit Customer"
      centered
      no-close-on-backdrop
    >
      <validation-observer v-slot="{ handleSubmit }" ref="VFormEditCustomer">
        <b-form @submit.prevent="handleSubmit(editCustomer())">
          <b-form-row>            
            <b-col md=12>
              <b-form-group label="Prospect Date" label-for="proSurveySchedule">
                <span>{{ dataModal.tp_date | moment("LLLL") }}</span>
              </b-form-group>
            </b-col>

            <b-col md=12>
              <b-form-group label="Customer Name" label-for="cusName">
                <b-form-input id="cusName" placeholder="e.g. Anna Puspita" v-model="dataModal.tp_client_name" />
                <VValidate name="Customer Name" v-model="dataModal.tp_client_name" :rules="{required: 1, min: 3, max: 128}" />
              </b-form-group>

              <b-form-group label="Phone Number" label-for="proNumber">
                <b-input-group prepend="+62">
                  <b-form-input id="proNumber" v-model="dataModal.tp_client_phone" type="tel" placeholder="e.g. 8565xxxxxxx" :formatter="telp" />
                </b-input-group>
                  <VValidate 
                    name="Phone Number" 
                    v-model="dataModal.tp_client_phone" 
                    :rules="{regex:/^(\+\d{1,3}[- ]?)?\d{10,13}$/, required: true}" 
                  />
              </b-form-group>

              <b-form-group label-for="prEmail">
                <template #label>Email <small>(Optional)</small></template>
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text><i class="fas fa-at"></i></b-input-group-text>
                  </template>
                  <b-form-input id="proEmail" v-model="dataModal.tp_client_email" type="email" placeholder="e.g. annapuspita@mail.com" />
                </b-input-group>
                  <VValidate name="Email" rules="email" v-model="dataModal.tp_client_email" />
              </b-form-group>

              <b-form-group label="Address" label-for="proAddress">
                <b-form-textarea id="proAddress" v-model="dataModal.tp_client_address" placeholder="e.g. Jl. Sudirman No. 5 Kuningan, Jakarta Barat - DKI Jakarta" rows="3" />
                <VValidate name="Address" :rules="{ required: 1, min: 5, max: 256 }" v-model="dataModal.tp_client_address" />
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button size="md" variant="transparent" @click="closeModal('editCustomer')">Cancel</b-button>
        <b-button size="md" variant="info" @click="editCustomer()">Update</b-button>
      </template>
    </b-modal>

  </b-card>
</b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'

export default {
  extends: GlobalVue,  
  data() {
    return {
      dateFilter: [],
      idKey:'tp_id',
      mrValidation: {},
      fields: [
        {
          key: 'number', label: '#', 
        },
        {
          key: 'aioc_date', label: 'Date',
        },
        {
          key: 'aioc_prospect_code', label: 'Code',
        },
        {
          key: 'aioc_note', label: 'Description', 
        },
        {
          key: 'aioc_receiver', label: 'Receiver', 
        },
        {
          key: 'aioc_debit', label: 'Debit', 
        },
        {
          key: 'aioc_credit', label: 'Credit', 
        },
        {
          key: 'aioc_balance', label: 'Balance', 
        },
      ],
    }
  },
  mounted(){
    this.apiGet()
    const a = []
    a[0] = this.$route.query['start-date'] || null
    a[1] = this.$route.query['end-date'] || null

    this.$set(this, 'dateFilter', a)
  },
  methods: {
    notAfterToday(date){
      return date > new Date()
    },
    doFilterDate(newVal){
      this.$set(this.filter, 'start-date', newVal[0] || '')
      this.$set(this.filter, 'end-date', newVal[1] || '')
      this.doFilter()
    },
  },
  computed: {
  },
  watch:{
    $route(){
      this.apiGet()
    }
  }
}
</script>